<template>
  <section
    class="Profile-view"
    :loading="loading"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="search-bar">
      <el-input
        v-model="searchString"
        placeholder="Search user, or document name"
        @keyup.native="onChangeSearchString"
        @clear="onChangeSearchString"
        clearable
      >
        <i
          slot="prefix"
          style="margin-top: -1px"
          class="el-input__icon el-icon-search"
        ></i>
      </el-input>
    </div>
    <hr />
    <div class="card mb-3" style="width: 100%">
      <div
        class=""
        style="width: 100%; min-height: 300px"
        v-loading="loading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <!-- <el-scrollbar wrap-style="max-height: 55vh; min-height: 160px"> -->
        <div
          class="sidemenu"
          v-if="
            Object.entries(allNotificationsGroupByDate).length &&
            Object.entries(allNotificationsGroupByDate).length >= 0
          "
        >
          <!-- v-if="allNotifications && allNotifications.length" -->
          <div
            v-for="(value, name, index) in allNotificationsGroupByDate"
            :key="name"
            class="group-by-date"
          >
            <div class="date-row">
              <span class="left-side">{{ name | getFormattedDate }}</span>
              <div class="right-side">
                <a
                  @click="markAsReadActivities"
                  v-if="index == 0 && filterType != 'All'"
                  class="mark-all-as-read"
                >
                  Mark all as read
                </a>
                <div v-if="index == 0">
                  <el-select
                    placeholder="Filter"
                    v-model="filterType"
                    class="filter-type-select"
                    @change="onFilterTypeSelected"
                  >
                    <el-option :key="0" :value="'All'" :label="'All'"
                      >All</el-option
                    >
                    <el-option :key="1" :value="'Unread'" :label="'Unread'"
                      >Unread</el-option
                    >
                  </el-select>
                </div>
              </div>
            </div>
            <div class="notification-row">
              <ul
                class="list-group list-group-flush"
                v-if="value && value.length"
              >
                <li
                  class="list-group-item notifcation-item"
                  v-for="(item, index) in value"
                  :key="index"
                  @click="goToDocView(item,true)"
                >
                  <div class="item-avatar" v-if="item.user_id">
                    {{ item.user_id | getUserNameAvatar }}
                    <div class="unread" v-if="!unmark(item)"></div>
                  </div>
                  <div class="item-avatar" v-if="item.contact_id">
                    {{ item.contact_id | getUserNameAvatar }}
                    <div class="unread" v-if="!unmark(item)"></div>
                  </div>
                  <div class="item-body">
                    <div class="item-body-left-child">
                      <div class="left-child" v-if="item.user_id">
                        <h4 class="name">
                          {{ item.user_id | getUserFullName }}
                        </h4>
                        <p class="mb-0 text-secondary" v-if="item.description">
                          {{ item.description }}
                          <!-- <span class="text-danger fw-medium">Offer Letter Doc</span> -->
                        </p>
                      </div>
                      <div class="left-child" v-if="item.contact_id">
                        <h4 class="name">
                          {{ item.contact_id | getUserFullName }}
                        </h4>
                        <p class="mb-0 text-secondary" v-if="item.description">
                          {{ item.description }}
                          <!-- <span class="text-danger fw-medium">Offer Letter Doc</span> -->
                        </p>
                      </div>
                      <span
                        class="doc-name"
                        v-if="
                          item.employee_document_id &&
                          item.employee_document_id.name
                        "
                      >
                        {{ item.employee_document_id.name }}
                      </span>
                      <span class="doc-name" v-if="item.company_document_id">
                        {{ item.description }}
                      </span>
                    </div>
                    <span class="text-dark fw-medium pl-0 opacity-80">
                      {{ item.created_at | getFormattedDateTypeThree }}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-else class="no-data-for-notification">
          <div class="filter-type-select-wrapper">
            <el-select
              placeholder="Filter"
              v-model="filterType"
              class="filter-type-select mt-1"
              @change="onFilterTypeSelected"
              size="mini"
            >
              <el-option :key="0" :value="'All'" :label="'All'">All</el-option>
              <el-option :key="1" :value="'Unread'" :label="'Unread'"
                >Unread</el-option
              >
            </el-select>
          </div>
          <div class="inner-wrapper">
            <div class="bg-img-wrapper">
              <div class="bg-img"></div>
            </div>
            <span>You have no unread notifications yet.</span>
            <p>
              When you receive a notification on a document, it will be
              displayed here as unread.
            </p>
          </div>
        </div>
        <!-- </el-scrollbar> -->
      </div>
    </div>
  </section>
</template>

<script>
import axios from "@/config/axiosWithCancel";
import { mapGetters,mapActions } from "vuex";

export default {
  name: "notifications-ViewAllNotifications",
  data() {
    return {
      filterType: "All",
      searchString: "",
      loading: true,
      allNotifications: [],
      allNotificationsGroupByDate: {},
      allNotificationsGroupByDateAll: {},
      allNotificationsGroupByDateUnread: {},
    };
  },
  components: {},
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser"]),
  },
  mounted() {
    this.fetchNotifications();
    this.fetchAllNotifications();
  },
  methods: {
     ...mapActions({
      fetchNotifications: "dashboard/fetchNotifications",
    }),
    unmark(doc) {
      if (
        doc.sender_email &&
        doc.sender_email.email == this.getAuthenticatedUser.email &&
        doc.sender_email.is_read &&
        doc.sender_email.is_read == true
      ) {
        return doc.sender_email.is_read;
      } else {
        let user = doc.rec_email.find(
          (x) =>
            x.email && x.email == this.getAuthenticatedUser.email && x.is_read
        );
        return user;
      }
    },
    async onFilterTypeSelected() {
      if (this.filterType == "All") {
        this.allNotificationsGroupByDate = this.allNotificationsGroupByDateAll;
      } else {
        this.allNotificationsGroupByDate =
          this.allNotificationsGroupByDateUnread;
      }
    },
    async onChangeSearchString() {
      this.fetchAllNotifications();
    },
    goToDocView(document,isChat = false) {
     
      if(isChat){
        this.markAsReadEachActivities({
        employee_document_id: document._id,
      });
      }
      if (document && document.employee_document_id) {
        if (document.document_status == "DRAFT") {
          this.$router.push({
            name: "employee-documents-custom-document-edit",
            params: {
              employee_document_id: document.employee_document_id._id,
            },
          });
        } else {
          this.$router.push({
            name: "employee-documents-custom-document",
            params: {
              employee_document_id: document.employee_document_id._id,
            },
            query: { tab: "chat" },
          });
        }
      } else {
        this.$router.push({
          path: "/templates",
        });
      }
    },
     async markAsReadEachActivities(query = {}) {
      try {
        await axios.post("all-documents/mark-as-read-each-activities", {
          query,
        });
        if (this.getNotificationsUnReadCnt > 0) {
          await this.fetchNotifications();
        }
      } catch (err) {
        console.log(">>> markAsReadActivities(), err", err);
      }
    },
    async markAsReadActivities(query = {}) {
      try {
        this.loading = true;
        await axios.post("all-documents/mark-as-read-activities", { query });
        await this.fetchAllNotifications();
      } catch (err) {
        console.log(">>> viewAllNotifications(), err", err);
      }
      this.loading = false;
    },
    async fetchAllNotifications() {
      this.searchItems = null;
      try {
        this.loading = true;
        const params = {
          search_string: this.searchString,
        };
        let response = await axios.get("all-documents/get-all-activities", {
          params,
        });
        this.allNotifications = response.data.data;
        this.allNotificationsGroupByDateAll = {};
        this.allNotifications.forEach((notification) => {
          // 2021-10-14
          var dt = notification.created_at.substring(0, 10);
          if (
            !Object.prototype.hasOwnProperty.call(
              this.allNotificationsGroupByDateAll,
              dt
            )
          ) {
            this.allNotificationsGroupByDateAll[dt] = [];
          }
          this.allNotificationsGroupByDateAll[dt].push(notification);
        });
        this.allNotificationsGroupByDateUnread = {};
        this.allNotifications.forEach((notification) => {
          // 2021-10-14
          if(notification && notification.sender_email){
              if(this.getAuthenticatedUser && this.getAuthenticatedUser.email && this.getAuthenticatedUser.email == notification.sender_email && !notification.sender_email.is_read || notification.sender_email.is_read == false){
                var dt = notification.created_at.substring(0, 10);
              if (
                !Object.prototype.hasOwnProperty.call(
                  this.allNotificationsGroupByDateUnread,
                  dt
                )
              ) {
                this.allNotificationsGroupByDateUnread[dt] = [];
              }
              this.allNotificationsGroupByDateUnread[dt].push(notification);
            
              }
          }else{
          notification.rec_email.forEach((el) => {
            if (this.getAuthenticatedUser && this.getAuthenticatedUser.email && this.getAuthenticatedUser.email == el.email && !el.is_read) {
              var dt = notification.created_at.substring(0, 10);
              if (
                !Object.prototype.hasOwnProperty.call(
                  this.allNotificationsGroupByDateUnread,
                  dt
                )
              ) {
                this.allNotificationsGroupByDateUnread[dt] = [];
              }
              this.allNotificationsGroupByDateUnread[dt].push(notification);
            }
          });
        }
        });
        this.onFilterTypeSelected();
        this.loading = false;
      } catch (err) {
        console.log(">>> fetchAllNotifications(), #3, err", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-bottom: none;
  .card-header {
    border-bottom: none;
  }
}
.sidemenu {
  height: 420px;
  overflow-y: auto;
  overflow-x: auto;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  scrollbar-width: thin;
}
.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.list-group {
  border: none;
  width: 100%;
  max-width: 100%;
  .notifcation-item {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0525);
    cursor: pointer;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: 2.75em 1fr;
    gap: 15px;
    @media (max-width: 499.98px) {
      // display: grid;
      padding: 5px 5px !important;
    }
    .item-avatar {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid #f754a2;
      background-color: rgba(#f754a2, $alpha: 0.1);
      color: #f754a2;
      .unread {
        position: absolute;
        z-index: 10000;
        width: 7px;
        height: 7px;
        top: 9px;
        left: 28px;
        border-radius: 8px;
        background: #40a0ff;
      }
    }
    .item-body {
      display: flex;
      justify-content: space-between;
      .item-body-left-child {
        .left-child {
          @media (min-width: 500px) {
            display: flex;
          }
        }
      }
      p {
        font-size: 13px;
        font-style: italic;
      }
      .name {
        font-style: normal;
        margin-bottom: 0;
        color: #6c757d !important;
        min-height: 21px;
        font-weight: 500;
        font-size: 15px !important;
        @media (min-width: 500px) {
          margin-right: 5px;
        }
      }
      .date-time {
        font-style: normal;
        min-width: 100px;
        @media (max-width: 499.98px) {
          min-width: 70px !important;
        }
        text-align: right;
        color: rgba(0, 0, 0, 0.5) !important;
        font-size: 12px;
      }
      .doc-name {
        font-style: normal;
        font-size: 13px !important;
        font-weight: 500;
        color: gray;
        margin-left: 5px;
      }
    }
  }
}
.el-scrollbar {
  // border: 1px solid rgba(0, 0, 0, 0.125);
  // border-radius: 1px;
}
.group-by-date {
  .date-row {
    padding: 15px 10px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    .mark-all-as-read {
      color: #409eff;
      cursor: pointer;
      margin-right: 20px;
      line-height: 38px;
    }
    .right-side {
      display: flex;
      flex-direction: row;
    }
    .left-side {
      line-height: 33px;
    }
  }
  .notification-row {
    padding: 0 0px;
  }
}
</style>

<style lang="scss">
.all-notifications-dialog {
  .el-dialog {
  }
  .el-dialog__body {
    padding: 0 !important;
  }
  // .Profile-view {
  //   .card {
  //     border: none !important;
  //   }
  // }
  hr {
    margin: 13px 0 !important;
  }
  .search-bar {
    margin-top: 12px;
    margin-left: 13px;
    margin-right: 10px;
    display: flex;
    i.el-icon-search {
      // border: 2px solid #e7eaf3 !important;
      // border-right: none !important;
      // padding: 10px;
      margin-top: 10px;
    }
    div.el-input {
      width: 95%;
      margin-left: 10px !important;
      // border: 2px solid #e7eaf3 !important;
      // border-left: none !important;
      // width: 98%;
      // &:focus-visible {
      //   outline: none !important;
      // }
    }
  }
  .filter-type-select {
    width: 120px;
    margin-right: 10px;
  }
  .no-data-for-notification {
    display: flex;
    flex-direction: column;
    .filter-type-select-wrapper {
      text-align: right;
      margin-right: 10px;
    }
  }
}
</style>