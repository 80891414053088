<template>
  <section
    class="dashboard-view"
    :class="{ mobile: getIsMobile, 'is-open': isCollapse }"
  >
    <el-row>
      <el-col :span="24">
        <TopbarDefault @open:sidebar="sidebarOpen"></TopbarDefault>
      </el-col>
    </el-row>
    <template>
      <el-row type="flex" justify="center">
        <el-col :xl="24" :lg="24" :md="24">
          <div class="canvas">
            <main id="MainLayout">
              <!-- <router-view :layout.sync="layout" /> -->
              <SideLayout />
            </main>
          </div>
        </el-col>
      </el-row>
    </template>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import TopbarDefault from "../layouts/TopbarDefault";
import SideLayout from "../components/Configuration/layout";
// import SidebarDefault from "../layouts/SidebarDefault";
// import HorizontalMenu from "../layouts/HorizontalMenu";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
// import appConfig from "@/config/app";
export default {
  name: "home",
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.getIsMobile) {
      this.$store.commit("navigationOpen/setIsOpen", false, {
        root: true,
      });
    }
  },
  components: {
    TopbarDefault,
    SideLayout,
  },
  mixins: [MobileRelatedHelper],
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("menuManagementV2", [
      "getMenu",
      "getMenuManagementAddStatus",
      "getMenuManagementUpdateStatus",
    ]),
    ...mapGetters("auth", [
      "getActiveWorkspace",
      "getAuthenticationDetails",
      "getAuthenticatedSSOToken",
    ]),
    ...mapGetters("company", [
      "getContactLoginSlug",
      "getUpdateCompany",
      "getCompanyDetails",
    ]),
    ...mapGetters("sso", ["getAuthenticatedSSOToken"]),
    isCollapse: {
      get() {
        return this.getIsOpen;
      },
      set(isCollapse) {
        this.$store.commit("navigationOpen/setIsOpen", isCollapse, {
          root: true,
        });
      },
    },
  },
  async mounted() {
    // let url = appConfig.APP_URL
    //   this.publicLink = `${url}/if/${this.getAuthenticatedSSOToken}${this.$route.path}`;
    //   this.privateLink = `${url}/if/${this.getAuthenticatedSSOToken}${this.$route.path}`;
    // await this.getCompanyInfo();
  },
  data() {
    return {
      layout: "div",
      offCanvas: true,
      sidebarStatus: false,
      windowWidth: 1500,
      isHorMenu: false,
      copyModal: false,
      getlink: "",
      activeTab: "first",
      publicLink: "",
      privateLink: "",
    };
  },
  methods: {
    async getCompanyInfo() {
      // this.loading = true;
      try {
        // await this.$store.dispatch(
        //   "company/fetchCompany",
        //   this.getActiveWorkspace.company_id
        // );
        if (this.getCompanyDetails) {
          // this.loading = false;
          this.brandingInfo = this.getCompanyDetails;
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    openViewConfigPage() {
      this.copyModal = true;
    },
    copyLinkToClipboard(refName) {
      this.$refs[refName].focus();
      document.execCommand("copy");
      this.$message.success("Link copied to clipboard");
    },
  },
  watch: {
    // '$route'(to) {
    //   let url = appConfig.APP_URL
    //   this.publicLink = `${url}/if/${this.getAuthenticatedSSOToken}${to.path}`;
    //   this.privateLink = `${url}/if/${this.getAuthenticatedSSOToken}${to.path}`;
    // },
  },
};
</script>

<style lang="scss">
.dashboard-view {
  &.mobile {
    &.is-open {
      // padding-left: 100px;
      .bg-canvas {
        display: block;
      }
    }
    .main-dashboard-view {
      padding-left: 30px;
      // padding-right: 30px;
    }
    .left-side-bar {
      position: absolute;
      z-index: 11000;
    }
    .offCanvas {
      margin-left: 0px;
      // background: rgba(0, 0, 0, .025);
    }
    .bg-canvas {
      background: rgba(0, 0, 0, 0.5);
      z-index: 10000;
      width: 100vw;
      height: 100vh;
      position: absolute;
      border: 1px solid red;
      top: 0;
      left: 0;
      display: none;
    }
  }
}
.dashboard-view {
  background: #ffffff;
  min-height: 100vh;
  z-index: 999;
  // min-height: calc(100vh - 68px);
  .main-dashboard-view {
    margin-top: 50px;
    padding-top: 70px !important;
    padding-left: 100px;
    padding-right: 30px;
  }
  .main-dashboard-view-with-sidebar {
    margin-top: 5vh !important;
    padding-top: 2% !important;
    padding-left: 5% !important;
    padding-right: 1% !important;
  }
  .edit-new-template-view {
    padding-left: 0;
  }
  .canvas {
    transition: all 0.2s ease-in-out;
  }
  .offCanvas {
    margin-left: 150px;
    transition: all 0.2s ease-in-out;
  }
  .icon-link {
    font-size: 24px;
    float: right;
  }
  .el-input--mini .el-input__inner {
    width: 95%;
  }
}
</style>
